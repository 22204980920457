import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { ref } from "vue";
import LangBase from "@/utils/Lang/common/LangBase";
import VantMsgLib from "@/utils/Vant/VantMsgLib";
import { useRouter } from "vue-router";
import LangCom from "@/utils/Lang/LangViews";
export default {
  __name: 'Login_SetLanguage',
  setup(__props) {
    const LangLib = new LangCom('Common', 'Login_SetLanguage');
    const GetLangTemplate = field => LangLib.GetTemplateLang(field);
    // const GetLangSetup = field => LangLib.GetSetupLang(field)

    const show = ref(false);
    //actions对象不需要翻译
    const actions = [{
      name: '中文',
      type: 'zh'
    }, {
      name: 'English',
      'type': 'en'
    }];
    const router = useRouter();
    let lib = new LangBase();
    const onSelect = item => {
      lib.SetNowLang(item.type);
      VantMsgLib.alertSuccess();
      router.go(0);
    };
    return (_ctx, _cache) => {
      const _component_van_cell = _resolveComponent("van-cell");
      const _component_van_action_sheet = _resolveComponent("van-action-sheet");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_cell, {
        "is-link": "",
        title: GetLangTemplate('cellTitle'),
        onClick: _cache[0] || (_cache[0] = $event => show.value = true)
      }, null, 8, ["title"]), _createVNode(_component_van_action_sheet, {
        show: show.value,
        "onUpdate:show": _cache[1] || (_cache[1] = $event => show.value = $event),
        actions: actions,
        onSelect: onSelect,
        "close-on-click-action": "",
        "cancel-text": GetLangTemplate('cancel_text')
      }, null, 8, ["show", "cancel-text"])], 64);
    };
  }
};